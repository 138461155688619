import React from "react";
import "../assets/css/pricing.css";
// import Navbar from './Navbar'
// import FirstCirlcle from '../assets/images/pricing/first-circle.svg'
// import SecondCirlce from '../assets/images/pricing/second-circle.svg'
// import rightone from '../assets/images/pricing/rightcolotic.svg'
import leftone from "../assets/images/pricing/leftcoltic.svg";
// import topselect from "../assets/images/pricing/top-select.png";
import { TiTick } from "react-icons/ti";
// import { color } from 'framer-motion';
const PricingPlans = () => {
  return (
    <>
      {/* <Navbar /> */}
      <div className="pricing-plans flex flex-col justify-center bg-light-600 dark:bg-dark-600 pt-20 h-screen max-md:h-full">
        <h2 className="text-gray-700 dark:text-gray-300">Plans & Pricing</h2>
        <div className="features w-[90%] mx-auto">
          <div className="feature-item flex gap-4 w-3/4">
            <div className="p-1 rounded-full box-border flex items-center justify-center bg-dark-600 dark:bg-light-600">
              <TiTick className="text-3xl text-white dark:text-green-700" />
            </div>
            {/* <img src={topselect} alt="" /> */}
            <p className="dark:text-gray-300">
              Plans for all firm sizes, with clear, no-hidden-fee pricing for
              confident budgeting
            </p>
          </div>
          <div className="feature-item flex gap-4 w-3/4">
            <div className="p-1 rounded-full box-border flex items-center justify-center bg-dark-600 dark:bg-light-600">
              <TiTick className="text-3xl text-white dark:text-green-700" />
            </div>
            {/* <img src={topselect} alt="" /> */}
            <p className="dark:text-gray-300">
              Our pricing plans includes access to a robust suite of tools and
              features tailored just for you
            </p>
          </div>
          <div className="feature-item flex gap-4 w-2/3">
            <div className="p-1 rounded-full box-border flex items-center justify-center bg-dark-600 dark:bg-light-600">
              <TiTick className="text-3xl text-white dark:text-green-700" />
            </div>
            {/* <img src={topselect} alt="" /> */}
            <p className="dark:text-gray-300">
              Offering dedicated customer support with every plan
            </p>
          </div>
        </div>

        <div className="pricing-details">
          <div className="pricing-plan free-plan w-[400px]">
            <div className="pricing-content-wrapper">
              <div className="pricing-main-content-1 bg-gray-300 dark:bg-dark-400">
                <div className="pl-5 py-2 pricing-upper-content">
                  <h3 className="dark:text-gray-300 text-gray-700">FREE</h3>
                  {/* <p style={{ fontFamily: 'raleway', fontSize: '28px', fontWeight: 'bold', color: '#5D54F1' }}></p> */}
                  <p className="pricing-para dark:text-gray-300">
                    Create an account and start for free. No credit card
                    required.
                  </p>
                  <button
                    className="max-sm:text-xs px-5 py-2 dark:bg-dark-500 dark:text-gray-300 font-raleway font-semibold rounded-lg border dark:border-dark-500 bg-light-500 text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[4px_4px_0px_0px_#313134] transition duration-200"
                    onClick={() => {
                      window.location.href = "/talkaboutcase";
                    }}
                  >
                    Use Our Chatbot
                  </button>
                </div>
                <ul>
                  <li className="flex items-center gap-4">
                    {/* <img src={leftone} alt="" /> */}
                    <div className="p-1 rounded-full box-border flex items-center justify-center bg-dark-600 dark:bg-light-600">
                      <TiTick className="text-base text-white dark:text-green-700" />
                    </div>
                    <span className="dark:text-gray-300">
                      Get personalised responses
                    </span>
                  </li>
                  <li className="flex items-center gap-4">
                    {/* <img src={leftone} alt="" /> */}
                    <div className="p-1 rounded-full box-border flex items-center justify-center bg-dark-600 dark:bg-light-600">
                      <TiTick className="text-base text-white dark:text-green-700" />
                    </div>
                    <span className="dark:text-gray-300">
                      Instant support from our advanced AI chatbot
                    </span>
                  </li>
                  <li className="flex items-center gap-4">
                    {/* <img src={leftone} alt="" /> */}
                    <div className="p-1 rounded-full box-border flex items-center justify-center bg-dark-600 dark:bg-light-600">
                      <TiTick className="text-base text-white dark:text-green-700" />
                    </div>
                    <span className="dark:text-gray-300">
                      Available 24/7 to assist with queries
                    </span>
                  </li>
                  <li className="flex items-center gap-4">
                    {/* <img src={leftone} alt="" /> */}
                    <div className="p-1 rounded-full box-border flex items-center justify-center bg-dark-600 dark:bg-light-600">
                      <TiTick className="text-base text-white dark:text-green-700" />
                    </div>
                    <span className="dark:text-gray-300">
                      View chat history and easily track previously accessed
                      information
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="pricing-plan premium-plan">
                        <div className="pricing-content-wrapper">
                            <div className="pricing-main-content-2">
                            <div className="upper-content">
                                <h3>Premium Subscription</h3>
                                <div className='free-trial'>
                                    <p style={{color:'white', fontSize:'20px', fontWeight:'700'}}>Free Trial For First 15 Days</p>
                                </div>
                                <button onClick={()=>{window.location.href='/mycases'}}>Unlock Live Case Tracking</button>
                            </div>
                                <ul>
                                    <li className="list-item">
                                        <img src={rightone} alt="" />
                                        <span>Everything included in our FREE plan</span>
                                    </li>
                                    <li className="list-item">
                                        <img src={rightone} alt="" />
                                        <span>Unlock additional features like live case tracking, performance analytics, documentation, lawyer matching & predictive analytics</span>
                                    </li>
                                    <li className="list-item">
                                        <img src={rightone} alt="" />
                                        <span>Enjoy a 15 day free trial for all</span>
                                    </li>
                                    <li className="list-item">
                                        <img src={rightone} alt="" />
                                        <span>Subsequently INR 7,999 per month</span>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </>
  );
};

export default PricingPlans;
