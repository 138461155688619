// React Utils
import React from "react";

// React Icons
import { MdOutlineUploadFile } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa6";

// Images
import FileUploadLight from "../../assets/images/FileUpload/FileUploadLight.png";
import FileUploadDark from "../../assets/images/FileUpload/FileUploadDark.png";

// Dialog Box
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from "../../components/ui/dialog";

import { Progress } from "../../components/ui/progress";

const FileUploadModal = ({
  selectedFile,
  setSelectedFile,
  getRootProps,
  getInputProps,
  isDragActive,
  loading,
  handleSend,
  uploadProgress,
  modalIsOpen,
  setModalIsOpen,
}) => {
  return (
    <Dialog
      open={modalIsOpen}
      onOpenChange={() => {
        setSelectedFile(null);
        setModalIsOpen();
      }}
    >
      <DialogTrigger>
        <MdOutlineUploadFile
          className="text-2xl text-black dark:text-white"
          onClick={() => setModalIsOpen(true)}
        />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload files</DialogTitle>
          <DialogDescription>Click on Browse and choose File</DialogDescription>
        </DialogHeader>
        <div
          {...getRootProps()}
          className={`${
            isDragActive ? "active" : ""
          } flex flex-col items-center border rounded-md p-5 cursor-pointer`}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the file here ...</p>
          ) : (
            <div className="flex flex-col items-center gap-4">
              <div>
                <img
                  src={FileUploadLight}
                  alt="upload file"
                  className="dark:hidden scale-75"
                />
                <img
                  src={FileUploadDark}
                  alt="upload file"
                  className="hidden dark:block scale-75"
                />
              </div>
              <div>
                <p className="font-raleway text-lg text-center">
                  Drag & Drop your file here <br />
                  or <span className="font-semibold">Browse</span>
                </p>
              </div>
              {selectedFile && (
                <div className="dark:text-gray-300 text-dark-600 text-center">
                  <ul className="font-semibold ">{selectedFile.name}</ul>
                </div>
              )}
              {!selectedFile ? (
                <div>
                  <p className="font-raleway">
                    JPEG, JPG, PNG, GIF, and PDF, Max 20 MB
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        {selectedFile && (
          <div className="flex flex-col gap-3 border p-4">
            <div className="flex gap-3 items-center">
              <FaFilePdf className="text-3xl text-red-400" />
              <div className="flex flex-col">
                <p className="text-sm">{selectedFile.name}</p>
                <p className="text-xs">{uploadProgress}% of 100%</p>
              </div>
            </div>
            <Progress className="h-1 bg-yellow-400" value={uploadProgress} />
          </div>
        )}
        <DialogFooter>
          <button
            className="px-4 py-2 bg-black text-white dark:bg-white dark:text-black font-medium"
            onClick={handleSend}
          >
            {loading ? "Please wait..." : "Upload"}
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default FileUploadModal;
