import React, { useEffect, useRef, useState, useCallback } from "react";
import "../assets/css/Chat.css";
import "../assets/css/checkbox.css";
import usericon from "../assets/images/chat/user-icon.svg";
import { useChat } from "../context/ChatContext";
import axiosInstance from "../utils/axiosInstance";
import importfile from "../assets/images/Chatbot/Chat_plus.svg";
import sendmesg from "../assets/images/Chatbot/Send_file.svg";
import mobileNewChat from "../assets/images/chat/mobileNewChat.svg";
import { useCaseContext } from "../context/CaseContext";
import { useDropzone } from "react-dropzone";
import Modal from "react-modal";
import DragAndDrop from "../assets/images/chat/dragAndDrop.svg";
import { toast } from "react-toastify";
import microphoneImage from "../assets/images/chat/icons8-microphone-30.png";
import { MdOutlineUploadFile } from "react-icons/md";
import { FaMicrophone } from "react-icons/fa";
import { BsSend } from "react-icons/bs";
import { IoStopOutline } from "react-icons/io5";
import { IoMdCloudUpload } from "react-icons/io";
import { Link } from "react-router-dom";
import FileUploadModal from "./FileUploadModal/FileUploadModal";

import { HiMiniSpeakerWave } from "react-icons/hi2";
import { MdOutlineContentCopy } from "react-icons/md";
import { FaRegThumbsUp } from "react-icons/fa6";
import { FaThumbsUp } from "react-icons/fa6";
import { FaRegThumbsDown } from "react-icons/fa6";
import { FaThumbsDown } from "react-icons/fa6";
import { IoMdCheckmark } from "react-icons/io";
import { BsStopCircleFill } from "react-icons/bs";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";

import copy from "copy-to-clipboard";
import { ms } from "date-fns/locale";

Modal.setAppElement("#root"); // Add this line to set the app element

const Chat = ({ userProfileImage, chatRoom, newChatStarted }) => {
  const { handleNewChat } = useChat();
  const {
    messages,
    addMessage,
    setMessages,
    setShowPrompts,
    showPrompts,
    fetchChatHistory,
    chatRoomCreated,
    startNewChat,
    setConversationStarted,
    conversationStarted,
  } = useChat();
  const access_token = sessionStorage.getItem("access_token");

  const { propmtsVisibel, setPropmtsVisibel } = useCaseContext();
  const msgEnd = useRef(null);
  const [input, setInput] = useState("");
  const [isPromptClick, setIsPromptClick] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const { chatRoom1, setChatRoom1, setSelectedChat } = useChat();
  const { selectedChat, isChatOpen, setIsChatOpen } = useChat();
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const { toggleSidebar, isOpen } = useCaseContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [isThinking, setIsThinking] = useState(false);

  const [isNewChatRoom, setIsNewChatRoom] = useState(false);
  const [userScrolledUp, setUserScrolledUp] = useState(false);
  const [isListening, setIsListening] = useState(false);

  const [disableSendButton, setDisableSendButton] = useState(false);
  const [showStopButton, setShowStopButton] = useState(false);
  const [stopSendButtonOnChatLimit, setStopSendButtonOnChatLimit] =
    useState(false);
  const [timer, setTimer] = useState();
  const [readAloudClicked, setReadAloudClicked] = useState(false);
  const [copyClicked, setCopyClicked] = useState(false);
  const [thumbsUpClicked, setThumbsUpClicked] = useState(false);
  const [thumbsDownClicked, setThumbsDownClicked] = useState(false);

  const [checked, setChecked] = useState(false);

  function stop() {
    clearTimeout(timer);
    setDisableSendButton(false);
    setShowStopButton(false);
    fetchChatHistory();
  }

  function handleSpeechClick(message) {
    setReadAloudClicked((prev) => !prev);
    const speech = new SpeechSynthesisUtterance();
    speech.text = message;
    window.speechSynthesis.speak(speech);
  }

  function handleSpeechStop() {
    setReadAloudClicked((prev) => !prev);
    speechSynthesis.cancel();
  }

  useEffect(() => {
    const stopSpeechOnRefresh = () => {
      window.speechSynthesis.cancel();
    };

    // Stop speech when the component unmounts
    window.addEventListener("beforeunload", stopSpeechOnRefresh);

    // Cleanup on unmount
    return () => {
      window.speechSynthesis.cancel();
      window.removeEventListener("beforeunload", stopSpeechOnRefresh);
    };
  }, []);

  function handleCopy(text) {
    copy(text);
    setCopyClicked(true);
    const timeoutId = setTimeout(() => {
      setCopyClicked(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }

  async function handleThumbsUpClick(selectedChat, id) {
    console.log(selectedChat, id);

    // try {
    //   const response = await axiosInstance.patch(
    //     `chatapp/chat-details/${id}/feedback/?chatroom_id=${selectedChat}`,
    //     {
    //       feedback: "like",
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${access_token}`,
    //       },
    //     }
    //   );
    //   console.log(response);
    //   messages?.[selectedChat].forEach((message) => {
    //     console.log(message.id);
    //     if (id === message.id) {
    //       console.log("Thumbs Up Clicked");
    //       setThumbsUpClicked(true);
    //     }
    //   });
    // } catch (err) {
    //   console.log(err);
    // }
  }

  async function handleThumbsDownClick(selectedChat, id) {
    console.log(selectedChat, id);

    // try {
    //   const response = await axiosInstance.patch(
    //     `chatapp/chat-details/${id}/feedback/?chatroom_id=${selectedChat}`,
    //     {
    //       feedback: "dislike",
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${access_token}`,
    //       },
    //     }
    //   );
    //   console.log(response);
    //   setThumbsUpClicked((prev) => !prev);
    // } catch (err) {
    //   console.log(err);
    // }
  }

  // User Initials
  const userInfo =
    sessionStorage.getItem("user-info") &&
    JSON.parse(sessionStorage.getItem("user-info"));
  const firstInitial = userInfo
    ? userInfo[0]?.user?.first_name?.substring(0, 1)
    : "";
  const lastInitial = userInfo
    ? userInfo[0]?.user?.last_name?.substring(0, 1)
    : "";

  const chatMessages = messages[selectedChat] || [];
  // console.log(chatMessages);

  // console.log(selectedChat);
  // console.log(chatMessages);

  const openModal = () => {
    setModalIsOpen(true);
    // console.log("click");
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedFile(null);
  };

  // Uploading a File
  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
    setConversationStarted(true);
    setShowPrompts(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (chatRoomCreated) {
    }
  }, [chatRoomCreated]);

  // When the chat gets displayed, it Scrolls from TOP to BOTTOM
  useEffect(() => {
    if (!userScrolledUp && msgEnd.current) {
      msgEnd.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [messages]);

  // MaybeChangeHere
  useEffect(() => {
    if (isChatOpen) {
      setShowPrompts(true);
    } else {
      setShowPrompts(false);
      setPropmtsVisibel(false);
    }
  }, [isChatOpen]);

  // After Clicking a Prompt.
  useEffect(() => {
    const sendOnPromptClick = async () => {
      if (isPromptClick) {
        await handleSend();
        setIsPromptClick(false);
        setConversationStarted(true);
        setShowPrompts(false);
      }
    };

    sendOnPromptClick();
  }, [isPromptClick, setConversationStarted]);

  useEffect(() => {
    const fetchChatrooms = async () => {
      try {
        const response = await axiosInstance.get("chatapp/chat-room/", {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const chatrooms = response.data;
        if (chatrooms.length === 0) {
          setUserHasInteracted(true);
          setShowPrompts(true);
        }
      } catch (error) {
        console.error("Failed to fetch chatrooms:", error);
      }
    };
    fetchChatrooms();
  }, [access_token]);

  const startnewchat = () => {
    handleNewChat(true);
  };

  const createNewChatRoom = async () => {
    let data;
    if (checked) {
      data = { is_kanoon: true };
    } else {
      data = {};
    }
    try {
      const response = await axiosInstance.post("chatapp/chat-room/", data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 201) {
        const newChatRoomId = response.data.id;
        setChatRoom1(newChatRoomId);
        setIsChatOpen(false);
        setUserHasInteracted(false);
        setChecked(false);

        // setSelectedChat(newChatRoomId);
        return newChatRoomId;
      }
    } catch (error) {
      if (error.response.status === 429) {
        toast.error(
          `Your free chatlimit is over. \n Please Upgrade your Plan.`
        );
      }
      setDisableSendButton(false);
      console.error("Error creating new chat room:", error);
      return null;
    }
  };

  const pollForNameAssignment = async (chatRoomId) => {
    const interval = setInterval(async () => {
      try {
        const response = await axiosInstance.get(
          `chatapp/chat-room/${chatRoomId}/`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data && response.data.chat_name) {
          clearInterval(interval);
          await fetchChatHistory();
        }
      } catch (error) {
        clearInterval(interval);
        console.error("Error checking name assignment:", error);
      }
    }, 2000);
  };

  useEffect(() => {
    startNewChat();
    const fetchChatHistory = async () => {
      try {
        const response = await axiosInstance.get("chatapp/chat-details/", {
          params: {
            chatroom_id: selectedChat,
          },
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });
        // console.log(response.data);
        if (response.data && response.data.length === 0) {
          // If chat history is empty, show the prompts
          setShowPrompts(true);
          setPropmtsVisibel(true);
        } else {
          // If chat history is not empty, hide the prompts
          setShowPrompts(false);
          setPropmtsVisibel(false);

          response.data.forEach((msg) => {
            addMessage(selectedChat, "user", msg.question);
            addMessage(selectedChat, "assistant", msg.answer);
          });
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
      }
    };
    fetchChatHistory();
  }, [selectedChat, access_token]);

  const handleSend = async () => {
    // if (!selectedFile) {
    //     toast.error('Please Select Proper File or Message ');
    //     return;
    // }
    let messageContent = input.trim();
    setInput("");
    if (!messageContent && !selectedFile) {
      toast.error("Please enter a message or upload a file to chat.");
      setLoading(false);
      return;
    }
    setLoading(true);
    if (messageContent || selectedFile) {
      if (messageContent) {
        setDisableSendButton(true);
      }
      if (isChatOpen || userHasInteracted) {
        const createdChatRoomId = await createNewChatRoom();
        if (createdChatRoomId) {
          setChatRoom1(createdChatRoomId);
          setUserHasInteracted(false);
          await sendMessage(messageContent, createdChatRoomId);
          // setIsChatOpen(false);
        }
      } else {
        await sendMessage(messageContent, selectedChat || chatRoom1);
      }
      // setInput("");
      setSelectedFile(null);
      setConversationStarted(false);
      setLoading(false);
    }
  };
  const sendMessage = async (messageContent, chatRoomId) => {
    setConversationStarted(true);
    // setShowPrompts(false);

    if (selectedFile) {
      const formData = new FormData();
      formData.append("document", selectedFile);
      formData.append("chatroom_id", chatRoomId);
      setIsUploading(true);
      try {
        const fileApiResponse = await axiosInstance.post(
          "chatapp/upload/",
          formData,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              let percentCompleted = Math.floor((loaded * 100) / total);
              console.log(`Upload Progress: ${percentCompleted}%`); // Logging for debugging
              setUploadProgress(percentCompleted);
            },
          }
        );
        if (fileApiResponse.status === 201) {
          if (messageContent) {
            await handleTextMessage(messageContent, chatRoomId);
          }
          toast.success(
            "File uploaded successfully! You can ask questions about it."
          );
          setUploadStatus("Uploaded");
          setUploadProgress(0);
        } else {
          console.error(`HTTP error! Status: ${fileApiResponse.status}`);
          setUploadStatus("Failed");
          toast.error("Failed to upload file. Please try again later.");
        }
      } catch (error) {
        console.error("Error sending message with file:", error);

        if (
          error.response &&
          error.response.data &&
          error.response.data.document
        ) {
          toast.error(error.response.data.document[0]);
        } else {
          toast.error("Error uploading file: " + error.message);
        }

        setUploadStatus("Failed");
      } finally {
        setIsUploading(false);
        setUploadProgress(0);
        closeModal();
      }
    } else if (messageContent) {
      await handleTextMessage(messageContent, chatRoomId);
    }
    setInput("");
    setSelectedFile(null);
    setConversationStarted(false);
  };

  const handleTextMessage = async (messageContent, chatRoomId) => {
    const messageIdx = (messages[chatRoomId] || []).length;
    addMessage(selectedChat, "user", messageContent);
    addMessage(selectedChat, "assistant", "Thinking...");

    try {
      const apiResponse = await axiosInstance.post(
        "chatapp/chat-details/",
        {
          question: messageContent,
          chatroom_id: chatRoomId,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (apiResponse.status === 201) {
        const questionId = apiResponse.data.id;
        // console.log(questionId);
        await pollForAnswer(questionId, chatRoomId, messageIdx);
      } else {
        console.error(`HTTP error! Status: ${apiResponse.status}`);
        setMessages((prevMessages) => ({
          ...prevMessages,
          [selectedChat]: prevMessages[selectedChat].map((msg, idx) =>
            idx === messageIdx + 1
              ? {
                  ...msg,
                  content: "Error loading response",
                  loading: false,
                }
              : msg
          ),
        }));
      }
    } catch (error) {
      console.error("Error fetching response from API:", error);
      setMessages((prevMessages) => ({
        ...prevMessages,
        [selectedChat]: prevMessages[selectedChat].map((msg, idx) =>
          idx === messageIdx + 1
            ? {
                ...msg,
                content:
                  "You’ve reached your limit of 10 chats as a new user. Upgrade to our paid package for unlimited access and more features.",
                loading: false,
              }
            : msg
        ),
      }));
      toast.error("Your daily chat limit has been exhausted.");
      setDisableSendButton(false);
      setStopSendButtonOnChatLimit(true);
    }
  };

  const pollForAnswer = async (questionId, chatRoomId, messageIdx) => {
    // setIsThinking(true);
    const interval = setInterval(async () => {
      try {
        const response = await axiosInstance.get(
          `chatapp/chat-details/${questionId}/`,
          {
            params: { chatroom_id: chatRoomId },
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && response.data.answer) {
          clearInterval(interval);
          setIsThinking(false);
          // Initialize the message with an empty string to be filled word by word
          setMessages((prevMessages) => ({
            ...prevMessages,
            [selectedChat]: prevMessages[selectedChat].map((msg, idx) =>
              idx === messageIdx + 1
                ? { ...msg, content: "", loading: true }
                : msg
            ),
          }));
          // console.log(chatRoomId);
          displayAnswerWordByWord(
            selectedChat,
            chatRoomId,
            response.data.answer,
            messageIdx
          );
        } else {
          const partialResponse = response.data.partial_answer || "";
          setMessages((prevMessages) => ({
            ...prevMessages,
            [selectedChat]: prevMessages[selectedChat].map((msg, idx) =>
              idx === messageIdx + 1
                ? {
                    ...msg,
                    content: `${msg.content} ${partialResponse}`,
                    loading: true,
                  }
                : msg
            ),
          }));
        }
      } catch (error) {
        clearInterval(interval);
        console.error("Error polling for answer:", error);
        // setIsThinking(false);
        setMessages((prevMessages) => ({
          ...prevMessages,
          [selectedChat]: prevMessages[selectedChat].map((msg, idx) =>
            idx === messageIdx + 1
              ? {
                  ...msg,
                  content: "Error loading response",
                  loading: false,
                }
              : msg
          ),
        }));
      }
    }, 1000);
  };

  const displayAnswerWordByWord = (
    selectedChat,
    chatRoomId,
    answer,
    messageIdx
  ) => {
    // console.log(
    //   "Starting displayAnswerWordByWord with selectedChat:",
    //   selectedChat
    // );
    const words = answer.split(" ");
    let index = 0;
    let displayedContent = "";
    setShowStopButton(true);

    const displayNextWord = () => {
      if (index < words.length) {
        displayedContent += (index > 0 ? " " : "") + words[index];
        setMessages((prevMessages) => {
          const chatMessages = prevMessages[selectedChat] || [];
          return {
            ...prevMessages,
            [selectedChat]: chatMessages.map((msg, idx) =>
              idx === messageIdx + 1
                ? { ...msg, content: displayedContent, loading: false }
                : msg
            ),
          };
        });
        index++;
        setTimer(
          setTimeout(() => {
            displayNextWord();
          }, 70)
        );
      } else {
        // console.log("Completed displaying answer word by word.");
        // console.log("Polling for name assignment with:", chatRoomId);
        pollForNameAssignment(chatRoomId);
        setDisableSendButton(false);
        setShowStopButton(false);
      }
    };

    // Initialize the content of the message if it does not exist
    setMessages((prevMessages) => {
      const chatMessages = prevMessages[selectedChat] || [];
      const updatedMessages = chatMessages.map((msg, idx) =>
        idx === messageIdx + 1 ? { ...msg, content: "", loading: true } : msg
      );

      return {
        ...prevMessages,
        [selectedChat]: updatedMessages,
      };
    });

    // Start the word display process
    displayNextWord();
  };

  const handleEnter = async (e) => {
    if (e.key === "Enter" && input.trim() !== "") {
      e.preventDefault();
      setIsPromptClick(true);
      setDisableSendButton(true);
      // setStopSendButtonOnChatLimit(true);
    }
  };

  const handlePromptClick = (prompt) => {
    setInput(prompt);
    setIsPromptClick(true);
    setConversationStarted(true);
    // handleSend();
  };

  const formatText = (text) => {
    if (!text) return null;
    const cleanText = text.replace(/【.*?】/g, "");

    const lines = cleanText.split("\n");

    return lines
      .map((line, index) => {
        if (!line.trim()) return null;

        // Handle headings
        const headingMatch = line.match(/^(#+)\s+(.*)/);
        if (headingMatch) {
          const level = headingMatch[1].length;
          const content = headingMatch[2].replace(/\*\*(.*?)\*\*/g, "$1");
          return <strong key={index}>{content}</strong>;
        }

        const numberedMatch = line.match(/^(\d+\.\s+)\*\*(.*?)\*\*$/);
        if (numberedMatch) {
          const numberPart = numberedMatch[1]; // e.g., "1. "
          const content = numberedMatch[2].trim(); // Get the text after ** and remove it
          return (
            <div key={index}>
              {numberPart}
              <strong>{content}</strong>
            </div>
          );
        }

        // Handle list items with bold text
        if (line.startsWith("-**")) {
          const content = line.substring(3, line.length - 2).trim();
          return (
            <ul>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("-**")) {
          const content = line.substring(8).trim();
          return (
            <ul>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("   - **")) {
          const content = line.substring(7).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("  - ")) {
          const content = line.substring(4).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul style={{ marginLeft: "10px" }}>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("   - ")) {
          const content = line.substring(4).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul style={{ marginLeft: "10px" }}>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }

        if (line.startsWith("     - ")) {
          const content = line.substring(6).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul style={{ marginLeft: "10px" }}>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }

        // Handle regular list items
        if (line.startsWith("-")) {
          const cleanLine = line.substring(1).trim();
          const contentWithoutBold = cleanLine.replace(/\*\*(.*?)\*\*/g, "$1");
          return (
            <p style={{ marginLeft: "10px" }}>
              <li key={index}>{contentWithoutBold}</li>
            </p>
          );
        }

        // Handle bold text within a paragraph
        const parts = line
          .replace(/\*\*$/, "")
          .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
          .split(/(<strong>.*?<\/strong>)/)
          .map((part, idx) => {
            if (part.startsWith("<strong>") && part.endsWith("</strong>")) {
              const cleanText = part.substring(8, part.length - 9);
              return <strong key={idx}>{cleanText}</strong>;
            } else {
              return <span key={idx}>{part}</span>;
            }
          });

        return (
          <p key={index} style={{ textAlign: "justify" }}>
            {parts}
          </p>
        );
      })
      .filter((component) => component !== null);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (msgEnd.current) {
        const isScrolledToBottom =
          msgEnd.current.getBoundingClientRect().bottom <= window.innerHeight;
        setUserScrolledUp(!isScrolledToBottom);
      }
    };

    const chatContainer = msgEnd.current?.parentNode;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleSpeechRecognition = () => {
    if (!("webkitSpeechRecognition" in window)) {
      toast.error(
        "Speech recognition is not supported in this browser. Try using Chrome."
      );
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = "en-US";
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onstart = () => {
      let audioStart = new Audio("/StartRecording.mp3");
      audioStart.play();
      setIsListening(true);
    };

    recognition.onend = () => {
      let audioStop = new Audio("/StopRecording.mp3");
      audioStop.play();
      setIsListening(false);
    };

    recognition.onresult = (event) => {
      const speechResult = event.results[0][0].transcript;
      setInput(speechResult);
      // sendMessage(speechResult);
    };

    recognition.onerror = (event) => {
      toast.error("Speech recognition error: " + event.error);
    };

    recognition.start();
  };

  return (
    <>
      <div className="sidebar1 dark:bg-dark-600 dark:text-gray-300 font-roboto leading-8 px-10 max-md:px-0">
        <div className="main w-2/3 mx-auto max-md:w-full">
          <div className="chats p-0">
            <div className="chatbot-mobile-content dark:bg-dark-500 bg-light-500">
              <div className="" onClick={toggleSidebar}>
                <i
                  className={`fas ${isOpen ? "fa-chevron-left" : "fa-bars"}`}
                ></i>
              </div>
              <p>LaWiz</p>
              <hr className="mobile-Chatbot-horizontal-line" />
              <img
                src={mobileNewChat}
                alt=""
                onClick={startnewchat}
                className="dark:invert-[1]"
              />
            </div>
            <div className="all-chats">
              {chatMessages.map((msg, index) => (
                <div
                  key={index}
                  className={`flex chat ${
                    msg.role
                  } px-5 py-2 flex gap-6 w-full ${
                    msg.role === "user" ? "justify-end" : ""
                  }`}
                >
                  {msg.role !== "user" ? (
                    <img
                      className={`chatimg ${
                        msg.role === "user" ? "order-2 px-0 mx-0" : ""
                      }`}
                      src={
                        msg.role === "user"
                          ? ""
                          : // "https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/user-icon.svg"
                            "https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/chatbot-log.svg"
                      }
                      alt="chat avatar"
                    />
                  ) : firstInitial && lastInitial ? (
                    <div className="flex px-3 py-2 order-2 justify-center font-raleway items-center rounded-full bg-gray-300 dark:bg-sh-dark-500">
                      <p>{firstInitial}</p>
                      <p>{lastInitial}</p>
                    </div>
                  ) : (
                    <img
                      src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/user-icon.svg"
                      alt="user avatar"
                      className="flex order-2"
                    />
                  )}
                  {msg.type === "file" ? (
                    <p className="chat-message w-full">
                      {msg.loading ? (
                        // <span>thinking...</span>
                        <div className="space-y-2 flex-1 w-full">
                          <div className="animate-pulse rounded-md bg-gray-400 dark:bg-gray-800  h-3 w-full"></div>
                          <div className="animate-pulse rounded-md bg-gray-400 dark:bg-gray-800 h-3 w-3/4"></div>
                        </div>
                      ) : (
                        <>
                          <p>{msg.content}</p> <br />
                          <a
                            href={msg.fileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Open File
                          </a>
                        </>
                      )}
                    </p>
                  ) : (
                    <p
                      className={`chat-message ${
                        msg.role !== "user" ? "w-full" : ""
                      }`}
                    >
                      {
                        msg.loading ? (
                          // <span>Thinking...</span>
                          <div className="space-y-2 flex-1 w-full">
                            <div className="animate-pulse  rounded-md bg-gray-400 dark:bg-gray-800 h-3 w-full"></div>
                            <div className="animate-pulse rounded-md bg-gray-400 dark:bg-gray-800 h-3 w-3/4"></div>
                          </div>
                        ) : msg.content.includes("10 chats as a new user") ? (
                          <>
                            {formatText(msg.content)}
                            Visit our{" "}
                            <Link
                              className="text-purple-500 font-semibold underline"
                              to="/pricing"
                            >
                              pricing page
                            </Link>{" "}
                            for more info.
                          </>
                        ) : (
                          <div className="flex flex-col gap-2">
                            {formatText(msg.content)}
                            {/* {msg.role !== "user" && (
                              <div className="rounded-md justify-between text-lg flex w-fit gap-3 font-semibold">
                                <TooltipProvider>
                                  <Tooltip>
                                    <TooltipTrigger>
                                      {readAloudClicked ? (
                                        <BsStopCircleFill
                                          onClick={handleSpeechStop}
                                        />
                                      ) : (
                                        <HiMiniSpeakerWave
                                          onClick={() =>
                                            handleSpeechClick(msg.content)
                                          }
                                        />
                                      )}
                                    </TooltipTrigger>
                                    <TooltipContent side={"bottom"}>
                                      {readAloudClicked ? "Stop" : "Read Aloud"}
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                                <TooltipProvider>
                                  <Tooltip>
                                    <TooltipTrigger disabled={copyClicked}>
                                      {copyClicked ? (
                                        <IoMdCheckmark className="cursor-pointer" />
                                      ) : (
                                        <MdOutlineContentCopy
                                          onClick={() =>
                                            handleCopy(msg.content)
                                          }
                                        />
                                      )}
                                    </TooltipTrigger>
                                    <TooltipContent side={"bottom"}>
                                      Copy
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                                <TooltipProvider>
                                  <Tooltip>
                                    <TooltipTrigger
                                      disabled={
                                        thumbsUpClicked || thumbsDownClicked
                                      }
                                    >
                                      {thumbsUpClicked ? (
                                        <FaThumbsUp className="cursor-pointer" />
                                      ) : (
                                        <FaRegThumbsUp
                                          className="cursor-pointer"
                                          onClick={() =>
                                            handleThumbsUpClick(
                                              selectedChat,
                                              msg.id
                                            )
                                          }
                                        />
                                      )}
                                    </TooltipTrigger>
                                    <TooltipContent side={"bottom"}>
                                      Like
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                                <TooltipProvider>
                                  <Tooltip>
                                    <TooltipTrigger
                                      disabled={
                                        thumbsUpClicked || thumbsDownClicked
                                      }
                                    >
                                      {thumbsDownClicked ? (
                                        <FaThumbsDown className="cursor-pointer" />
                                      ) : (
                                        <FaRegThumbsDown
                                          className="cursor-pointer"
                                          onClick={() =>
                                            handleThumbsDownClick(
                                              selectedChat,
                                              msg.id
                                            )
                                          }
                                        />
                                      )}
                                    </TooltipTrigger>
                                    <TooltipContent side={"bottom"}>
                                      Dislike
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              </div>
                            )} */}
                          </div>
                        )
                        // formatText(msg.content)
                      }
                    </p>
                  )}
                </div>
              ))}
            </div>
            <div ref={msgEnd} />

            {/* {console.log(propmtsVisibel)} */}

            {(showPrompts || propmtsVisibel) && (
              <div>
                <div className="mobile-prompts max-md:px-6 dark:text-gray-300 leading-[1.6] mt-10 font-raleway font-semibold">
                  <div
                    className={`prompts ${conversationStarted ? "hidden" : ""}`}
                  >
                    <button
                      onClick={() =>
                        handlePromptClick(
                          "Hello! How can you assist me with my legal needs?"
                        )
                      }
                      className="dark:bg-dark-450 bg-light-600 px-5 py-2 transition-none"
                    >
                      Hello! How can you assist me with my legal needs?
                    </button>
                    <button
                      className="button2 dark:bg-dark-450 bg-light-600 px-5 py-2 transition-none"
                      onClick={() =>
                        handlePromptClick(
                          "Can you summarize the key points of my case?"
                        )
                      }
                    >
                      Can you summarize the key points of my case?
                    </button>
                  </div>
                  <div
                    className={`prompts ${conversationStarted ? "hidden" : ""}`}
                  >
                    <button
                      onClick={() =>
                        handlePromptClick("What services do you offer?")
                      }
                      className="dark:bg-dark-450 bg-light-600 px-5 py-2 transition-none"
                    >
                      What services do you offer?
                    </button>
                    <button
                      className="button2 dark:bg-dark-450 bg-light-600 px-5 py-2 transition-none"
                      onClick={() =>
                        handlePromptClick("How can you assist me with my case?")
                      }
                    >
                      How can you assist me with my case?
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="chatFooter dark:bg-dark-600 w-2/3 max-md:w-full mx-auto">
          {!messages?.[selectedChat]?.length > 0 && (
            <div className="flex items-center gap-4 pl-5 mb-2">
              <div class="checkbox-wrapper-3 mt-1.5">
                <input
                  type="checkbox"
                  id="cbx-3"
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
                <label for="cbx-3" class="toggle">
                  <span></span>
                </label>
              </div>
              <p className="flex flex-col text-gray-700 dark:text-gray-200">
                Search Judgements via Indian Kanoon
              </p>
            </div>
          )}

          <div className="inp dark:border-sh-dark-500 border-2 rounded-full px-5 py-2 max-md:py-1">
            <div className="file-upload">
              <div className="flex gap-3">
                {/* <button
                  onClick={openModal}
                  style={{
                    display: selectedFile ? "none" : "block",
                    color: "black",
                    backgroundColor: "transparent",
                  }}
                >
                  <img src={importfile} alt="" />
                  <MdOutlineUploadFile className="dark:text-gray-300 text-dark-600 text-2xl" />
                </button> */}
                <FileUploadModal
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  setConversationStarted={setConversationStarted}
                  setShowPrompts={setShowPrompts}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  isDragActive={isDragActive}
                  handleSend={handleSend}
                  loading={loading}
                  uploadProgress={uploadProgress}
                  modalIsOpen={modalIsOpen}
                  setModalIsOpen={setModalIsOpen}
                />
                <button
                  className="text-xl"
                  onClick={handleSpeechRecognition}
                  disabled={loading}
                >
                  <FaMicrophone className="text-dark-600 dark:text-white" />
                </button>
                {/* {selectedFile && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ marginRight: "8px", color: "black" }}>
                      {selectedFile.name}
                    </span>
                    <button
                      onClick={() => setSelectedFile(null)}
                      style={{ marginLeft: "auto", backgroundColor: "black" }}
                    >
                      ×
                    </button>
                  </div>
                )} */}
              </div>
            </div>
            <input
              type="text"
              placeholder="Start typing..."
              value={input}
              // Important
              onKeyDown={!disableSendButton ? handleEnter : undefined}
              onChange={(e) => setInput(e.target.value)}
              style={{ fontSize: "16px", borderRadius: "2px" }}
              className="h-9 rounded-full dark:bg-dark-600 text-dark-600 dark:text-white"
            />
            <div style={{ display: "flex", gap: "5px" }}>
              {showStopButton ? (
                <button className="p-2 box-border bg-red-900" onClick={stop}>
                  <IoStopOutline />
                </button>
              ) : (
                <button
                  className="p-2 box-border"
                  onClick={() => {
                    setIsPromptClick(true);
                  }}
                  disabled={disableSendButton || stopSendButtonOnChatLimit}
                  style={
                    disableSendButton || stopSendButtonOnChatLimit
                      ? { background: "#313134", cursor: "no-drop" }
                      : { background: "#0d0d0d" }
                  }
                >
                  <BsSend className="text-base" />
                </button>
              )}
              {/* <button
                className="p-2 box-border"
                onClick={() => {
                  setIsPromptClick(true);
                }}
                disabled={disableSendButton}
                style={
                  disableSendButton
                    ? { background: "#313134", cursor: "no-drop" }
                    : { background: "#0d0d0d" }
                }
              >
                <BsSend className="text-base" />
              </button>
              <button
                className="p-2 box-border bg-red-900"
                onClick={stop}
                disabled={!disableSendButton}
              >
                <IoStopOutline />
              </button> */}
            </div>
          </div>
          <div className="max-md:flex-col text-below-button  dark:text-gray-300 m-0 p-0 flex my-2 justify-between w-full">
            <p className="max-sm:leading-6 leading-[15px]">
              <span className="font-bold">Disclaimer:</span> Information
              provided may be inaccurate and is not legal advice.
            </p>
            <p className="max-sm:leading-3 leading-[15px]">
              <span className="font-bold">Note:</span> Information has been
              taken from eCourts.
            </p>
          </div>
          {/* <p className="text-xs font-bold text-center mx-auto underline text-gray-700 dark:text-gray-200">
            Chatbot can make mistakes. Confirm critical details.
          </p> */}
        </div>
        {/* <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="File Upload Modal"
          className="modal-overlay bg-black w-full h-screen"
          overlayClassName="modal-overlay"
        >
          <div className="modal-content dark:bg-dark-450 bg-light-600">
            <div className="file-upload-modal">
              <div className="modal-upper-section">
                <h2 className="modal-heading dark:text-gray-300">
                  Upload Files
                </h2>
                <button
                  className="closeIcon dark:text-gray-300 text-lg"
                  onClick={closeModal}
                >
                  x
                </button>
              </div>
              <div className="modal-top">
                <div className="modal-right-section">
                  <div
                    {...getRootProps()}
                    className={`dropzone ${
                      isDragActive ? "active" : ""
                    } flex flex-col items-center border rounded-md`}
                  >
                    <input {...getInputProps()} />
                    <img src={DragAndDrop} alt="" className="w-1/2" />
                    <IoMdCloudUpload className="text-9xl dark:invert-[1]" />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <div className="Browse_button">
                        <p className="dark:text-gray-300">
                          Drop & Drag your files here or
                        </p>
                        <button className="bg-light-500 dark:bg-dark-500 dark:text-gray-300 text-dark-600 dark:border-dark-500 font-raleway text-sm font-semibold hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[2.5px_2.5px_0px_0px_#313134] transition duration-200 border px-3 py-1 hover:text-black">
                          Browse Files
                        </button>
                        {selectedFile && (
                          <div className="selected-files dark:text-gray-300 text-dark-600 font-semibold">
                            <ul>{selectedFile.name}</ul>
                          </div>
                        )}
                        {!selectedFile ? (
                          <>
                            <p className="belowPara dark:text-gray-300">
                              {" "}
                              <span style={{ fontWeight: "600" }}>
                                Supported formats:
                              </span>{" "}
                              jpg, jpeg, png, gif, pdf
                              <br />
                              <span style={{ fontWeight: "600" }}>
                                Max Size:
                              </span>{" "}
                              20 mb
                            </p>
                            <p className="dark:text-gray-300">
                              Maximum 1 file to be uplaoded.
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-buttons">
                <button
                  className="upload-button dark:bg-sh-dark-500 dark:text-white text-gray-600"
                  onClick={handleSend}
                >
                  {loading ? "please wait" : "Upload"}
                </button>
                <button
                  className="upload-button dark:bg-dark-450 dark:text-white text-gray-600"
                  style={{
                    backgroundColor: "transparent",
                  }}
                  onClick={closeModal}
                >
                  Cancel{" "}
                </button>
              </div>
            </div>
          </div>
        </Modal> */}
      </div>
    </>
  );
};

export default Chat;
