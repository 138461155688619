// React Utils
import React, { useState } from "react";

// Data
import { statesAndCities } from "./data";

// Icons
import { MdOutlineEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GrContactInfo } from "react-icons/gr";
import { CgArrowTopRight } from "react-icons/cg";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
// import { FaPhoneAlt } from "react-icons/fa";
// import { FaBirthdayCake } from "react-icons/fa";

// ShadCN Components
import { Input } from "../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { Textarea } from "../../components/ui/textarea";

// Axios
import axiosInstance from "../../utils/axiosInstance";
import LawyerProfile from "./LawyerProfile";
import { toast } from "react-toastify";

const UserProfile = () => {
  // User Initials
  const userInfo =
    sessionStorage.getItem("user-info") &&
    JSON.parse(sessionStorage.getItem("user-info"));
  const firstInitial = userInfo
    ? userInfo[0]?.user?.first_name !== ""
      ? userInfo[0]?.user?.first_name?.substring(0, 1)
      : "C"
    : "";
  const lastInitial = userInfo
    ? userInfo[0]?.user?.last_name !== ""
      ? userInfo[0]?.user?.last_name?.substring(0, 1)
      : "M"
    : "";
  const id = userInfo[0]?.id;

  const [userData, setUserData] = useState({
    user: {
      firstName: userInfo ? userInfo[0]?.user?.first_name : "",
      lastName: userInfo ? userInfo[0]?.user?.last_name : "",
      is_lawyer: userInfo ? userInfo[0]?.user?.is_lawyer : false,
      email: userInfo ? userInfo[0]?.user?.email : "-",
    },
    dateOfBirth: userInfo ? userInfo[0]?.dob : "-",
    mobileNo: userInfo ? userInfo[0]?.mobile_number : "-",
    state: userInfo ? userInfo[0]?.state : "",
    city: userInfo ? userInfo[0]?.city : "",
    pinCode: userInfo ? userInfo[0]?.pincode : "",
    bio: userInfo ? userInfo[0]?.bio : "",
    practice_area: userInfo ? userInfo[0]?.practice_area : "",
    court_of_practice: userInfo ? userInfo[0]?.court_of_practice : "",
    experience: userInfo ? userInfo[0]?.experience : "",
    barcode_number: userInfo ? userInfo[0]?.barcode_number : "",
    language: userInfo ? userInfo[0]?.language : "",
    facebook: userInfo ? userInfo[0]?.facebook : "",
    linkedin: userInfo ? userInfo[0]?.linkedin : "",
    twitter: userInfo ? userInfo[0]?.twitter : "",
    whatsapp_number: userInfo ? userInfo[0]?.whatsapp_number : "",
    published_workds: userInfo ? userInfo[0]?.published_workds : "",
    honors_and_awards: userInfo ? userInfo[0]?.honors_and_awards : "",
  });

  const states = Object.keys(statesAndCities); // All States
  const cities = userData?.state ? statesAndCities[userData?.state] : []; // All Cities

  const access_token = sessionStorage.getItem("access_token"); // Access Token

  const handleLawyerProfileClick = () => {
    setUserData((prev) => ({
      ...prev,
      user: { ...prev.user, is_lawyer: true },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    let data = {};
    for (let keyValue of formData.entries()) {
      data[keyValue[0]] = keyValue[1];
    }

    delete data.first_name;
    delete data.last_name;

    data = {
      ...data,
      user: {
        first_name: userData.user.firstName,
        last_name: userData.user.lastName,
      },
    };

    console.log(data, "DATA");

    try {
      const response = await axiosInstance.put(`api/profile/${id}/`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });

      console.log(response.data);
      sessionStorage.setItem("user-info", JSON.stringify([response.data]));
      setUserData(response.data);
      toast.success("Profile Updated Successfully.");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex flex-col gap-10 font-raleway px-10 py-5 pt-20">
      {/* Top Section */}
      <div className="px-6 py-5 bg-gray-200 dark:bg-dark-400 rounded-md relative">
        <div className="flex items-center gap-12">
          <div className="flex gap-1 justify-center px-9 py-11 items-center rounded-full bg-gray-300 dark:bg-sh-dark-500">
            <p className="text-4xl">{firstInitial}</p>
            <p className="text-4xl">{lastInitial}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h4 className="font-semibold text-2xl">
              {userInfo[0]?.user?.first_name} {userInfo[0]?.user?.last_name}
            </h4>
            <div className="flex gap-6">
              <div className="flex items-center gap-2">
                <MdOutlineEmail />
                <p>{userInfo[0]?.user?.email}</p>
              </div>
              <div className="flex items-center gap-2">
                <FaMapMarkerAlt />
                <p>
                  {userInfo[0]?.city} {userInfo[0]?.state}{" "}
                  {userInfo[0]?.pincode}
                </p>
              </div>
              {/* <div className="flex items-center gap-2">
                <FaPhoneAlt />
                <p>{userInfo[0]?.mobile_number}</p>
              </div>
              <div className="flex items-center gap-2">
                <FaBirthdayCake />
                <p>{userInfo[0]?.dob}</p>
              </div> */}
            </div>
            <div className="flex items-center gap-2">
              <GrContactInfo />
              <p>{userInfo[0]?.bio}</p>
            </div>
          </div>
        </div>
        {userData?.user?.is_lawyer && (
          <button
            onClick={() =>
              setUserData((prev) => ({
                ...prev,
                user: { ...prev.user, is_lawyer: false },
              }))
            }
            className="flex items-center absolute bottom-5 text-xs right-10 px-2 py-2 font-semibold dark:bg-light-600 bg-dark-600 text-gray-200 dark:text-gray-700"
          >
            <MdOutlineKeyboardArrowLeft className="text-base" />
            Switch Back to User Profile
          </button>
        )}
      </div>
      {/* Bottom Section */}
      {userData?.user?.is_lawyer ? (
        <LawyerProfile userData={userData} setUserData={setUserData} id={id} />
      ) : (
        <div className="flex gap-6">
          {/* Form */}
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-[80%]">
            <div className="flex gap-4">
              <div className="w-1/2">
                <label>First Name *</label>
                <Input
                  name="first_name"
                  type="text"
                  placeholder="Enter Your First Name"
                  value={userData?.user?.firstName}
                  onChange={(e) =>
                    setUserData((prev) => ({
                      ...prev,
                      user: { ...prev.user, firstName: e.target.value },
                    }))
                  }
                  required
                />
              </div>
              <div className="w-1/2">
                <label>Last Name *</label>
                <Input
                  name="last_name"
                  type="text"
                  placeholder="Enter Your Last Name"
                  value={userData?.user?.lastName}
                  onChange={(e) =>
                    setUserData((prev) => ({
                      ...prev,
                      user: { ...prev.user, lastName: e.target.value },
                    }))
                  }
                  required
                />
              </div>
            </div>
            <div>
              <label>Email *</label>
              <Input
                name="email"
                type="email"
                placeholder="Enter Your Email"
                value={userData?.user?.email}
                onChange={(e) =>
                  setUserData((prev) => ({
                    ...prev,
                    user: { ...prev.user, email: e.target.value },
                  }))
                }
                required
                disabled
              />
            </div>
            <div>
              <label>Date of Birth</label>
              <Input
                name="dob"
                type="date"
                placeholder="Enter Your DOB"
                value={userData?.dateOfBirth}
                onChange={(e) =>
                  setUserData((prev) => ({
                    ...prev,
                    dateOfBirth: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <label>Mobile Number *</label>
              <Input
                name="mobile_number"
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                type="text"
                placeholder="Enter Your Mobile Number"
                value={userData?.mobileNo}
                maxlength="10"
                minlength="10"
                onChange={(e) =>
                  setUserData((prev) => ({
                    ...prev,
                    mobileNo: e.target.value,
                  }))
                }
                required
              />
            </div>
            <div>
              <label>State *</label>
              <Select
                name="state"
                onValueChange={(value) => {
                  setUserData((prev) => ({ ...prev, state: value, city: "" }));
                }}
                value={userData?.state}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select Your State" />
                </SelectTrigger>
                <SelectContent>
                  {states.map((state) => (
                    <SelectItem value={state} key={state}>
                      {state}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div>
              <label>City *</label>
              <Select
                name="city"
                onValueChange={(value) =>
                  setUserData((prev) => ({ ...prev, city: value }))
                }
                disabled={!userData?.state}
                value={userData?.city}
              >
                <SelectTrigger className="w-full">
                  <SelectValue
                    placeholder={
                      !userData.state ? "Select State First" : "Select City"
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  {cities?.map((city) => (
                    <SelectItem key={city} value={city}>
                      {city}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div>
              <label>Pin Code</label>
              <Input
                name="pincode"
                type="text"
                placeholder="Enter Your Pin Code"
                value={userData?.pinCode}
                onChange={(e) =>
                  setUserData((prev) => ({
                    ...prev,
                    pinCode: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <label>Bio</label>
              <Textarea
                name="bio"
                placeholder="Enter Your Bio"
                value={userData?.bio}
                onChange={(e) =>
                  setUserData((prev) => ({
                    ...prev,
                    bio: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <button
                className="bg-dark-600 dark:text-gray-700 dark:bg-light-600 text-gray-200 px-8 font-semibold tracking-wider py-2"
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
          {/* Upgrade */}
          <div className="flex flex-col justify-center items-center gap-6 rounded-md text-center bg-[url('/src/assets/images/UserProfile/LawyerUpgrade.png')] text-white bg-no-repeat bg-cover bg-black">
            <p className="font-semibold text-xl w-1/2">
              If you are a lawyer looking to increase your visibility, join our
              platform.
            </p>
            <button
              onClick={handleLawyerProfileClick}
              className="flex items-center gap-2 bg-white px-3 py-2 text-gray-700 font-medium"
            >
              <CgArrowTopRight className="text-2xl" />
              Upgrade to Lawyer's Profile
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
