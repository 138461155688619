// React Utils
import React from "react";

// Outlext Context
import { useOutletContext } from "react-router-dom";

// Accordion Shadcn
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";

const SimilarCases = () => {
  // Data from HighCourtDelhiDetails Component Outlet
  const { caseDetails } = useOutletContext();

  return (
    <div className="flex flex-col gap-10">
      <div>
        <p className="text-xl font-semibold font-raleway border-b-2 pb-1 w-fit">
          Similar Cases Found
        </p>
      </div>
      <div className="flex flex-col gap-10">
        {caseDetails?.analytics?.["Similar Cases Found"].length > 0
          ? caseDetails?.analytics?.["Similar Cases Found"]?.map(
              (cases, index) => (
                <div className="px-10 flex gap-6 w-full" key={index}>
                  <p className="text-2xl font-medium mt-3">{index + 1}.</p>
                  <Accordion
                    type="single"
                    collapsible
                    className="border border-[#D9D9D9] dark:border-gray-600 px-5 rounded-lg w-full"
                  >
                    <AccordionItem value="item-1" className="border-none">
                      <AccordionTrigger className="text-gray-700 dark:text-gray-200 hover:no-underline">
                        <div className="flex flex-col gap-4">
                          <div className="flex gap-10">
                            <p className="text-left min-w-[150px] font-normal">
                              Case Number
                            </p>
                            <p>{cases?.["Case Number"]}</p>
                          </div>
                          <div className="flex gap-10">
                            <p className="text-left min-w-[150px] font-normal">
                              Section & Acts
                            </p>
                            <div className="flex flex-wrap gap-x-2 gap-y-2">
                              {cases?.["Sections"]?.map((section, index) => (
                                <p key={index}>{section} |</p>
                              ))}
                            </div>
                          </div>
                          <div className="flex gap-10">
                            <p className="text-left min-w-[150px] font-normal">
                              Decision
                            </p>
                            <p>
                              {cases?.["Decision"][0].toUpperCase() +
                                cases?.["Decision"].slice(1)}
                            </p>
                          </div>
                          <div className="flex gap-10">
                            <p className="text-left min-w-[150px] font-normal">
                              Judge(s) Name
                            </p>
                            <div className="flex flex-wrap gap-x-2 gap-y-2">
                              {cases?.["Judges"]
                                ? cases?.["Judges"]?.map((judge, index) => (
                                    <p key={index}>{judge} |</p>
                                  ))
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <hr />
                        <div className="py-4 flex flex-col gap-2">
                          <p className="font-raleway w-fit text-lg font-medium text-blue- border-b-2">
                            Summary
                          </p>
                          <p className="text-base leading-[1.8] tracking-[0.3px]">
                            {cases?.["Explanation"]}
                          </p>
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </div>
              )
            )
          : "No Similar Cases Found."}
      </div>
      <hr />
    </div>
  );
};

export default SimilarCases;
