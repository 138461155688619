// React Util
import React, { useState } from "react";

// Notification Context
import { useNotificationContext } from "../../context/NotificationContext";

// Axios
import axiosInstance from "../../utils/axiosInstance";

// Toast Notification
import { toast } from "react-toastify";

// Dialog Box
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from "../../components/ui/dialog";

// Select Input
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

// TextArea
import { Textarea } from "../../components/ui/textarea";

const FeedbackForm = () => {
  const { isPopupVisible, setIsPopupVisible } = useNotificationContext();
  const [rating, setRating] = useState(0); // Number
  const access_token = sessionStorage.getItem("access_token"); // Authorization

  // Handle Feedback Form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Extracting Data from Form.
    const form = e.target;
    const formData = new FormData(form);
    formData.append("ratings", rating);
    const data = {};
    for (let keyValue of formData.entries()) {
      data[keyValue[0]] = keyValue[1];
    }

    // Sending POST API Request.
    try {
      const response = await axiosInstance.post("feedback/feedback/", data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response);
      toast.success("Feedback submitted successfully.");
    } catch (err) {
      console.log(err);
    } finally {
      setIsPopupVisible(false);
    }
  };

  return (
    <Dialog open={isPopupVisible} onOpenChange={setIsPopupVisible}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Send us your Feedback !</DialogTitle>
          <DialogDescription>
            Do you have a suggestion or had any problems? Let us know in the
            fields below.
          </DialogDescription>
        </DialogHeader>
        <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-2">
            <p>Choose the page you want to rate.</p>
            <Select name="service">
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a Page" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="HOMEPAGE">Home Page</SelectItem>
                <SelectItem value="CHATBOT">Chat Bot</SelectItem>
                <SelectItem value="CASE TRACKING">
                  Live Case Tracking
                </SelectItem>
                <SelectItem value="LEGAL CASE SEARCH">
                  Judgement Search
                </SelectItem>
                <SelectItem value="OTHER">Other</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-1">
            <p>Rate your experience with us.</p>
            <RatingReview rating={rating} setRating={setRating} />
          </div>
          <div className="flex flex-col gap-2">
            <p>Tell us about your experience.</p>
            <Textarea
              name="description"
              placeholder="Type your message here."
            />
          </div>

          <DialogFooter>
            <button
              className="w-full py-2 font-semibold bg-dark-600 text-gray-200 dark:bg-light-600 dark:text-gray-700"
              type="submit"
            >
              Submit
            </button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

function RatingReview({ rating, setRating }) {
  return (
    <div className="flex">
      {[1, 2, 3, 4, 5].map((star, idx) => {
        return (
          <span
            key={idx}
            className={`cursor-pointer text-3xl ${
              rating >= star
                ? "text-yellow-400"
                : "dark:text-gray-400 text-gray-500"
            }`}
            onClick={() => {
              setRating(star);
            }}
          >
            {" "}
            ★{" "}
          </span>
        );
      })}
    </div>
  );
}

export default FeedbackForm;
