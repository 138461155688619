// React Utils
import React from "react";

// ShadCN Sheet
import { Sheet, SheetContent, SheetTrigger } from "../../components/ui/sheet";

// Outlet Context
import { useOutletContext } from "react-router-dom";

// Moment
import moment from "moment";

// Icons
import { FaFilePdf } from "react-icons/fa6";

const Timeline = () => {
  // Data from HighCourtDelhiDetails Component Outlet
  const { caseDetails } = useOutletContext();
  console.log(caseDetails);

  // Array of PDF files.
  let timelines;
  if (caseDetails?.court === "supreme") {
    timelines = [caseDetails?.latest_pdf];
  } else if (caseDetails?.court === "district") {
    timelines = [caseDetails?.latest_order_pdf_link];
  } else {
    timelines = caseDetails?.order_pdfs;
  }

  return (
    <div className="flex flex-col gap-4 px-3 pb-6 border-b border-b-[#d9d9d9] dark:border-b-gray-600">
      <ul className="flex flex-col gap-0">
        {timelines?.map((timeline) => {
          return (
            <li
              key={timeline?.order_date}
              className="flex flex-col gap-0 rounded-lg px-3 py-4"
            >
              <p className="font-semibold">
                Order Date :{" "}
                {timeline?.order_date
                  ? moment(timeline?.order_date).format("DD MMMM YYYY")
                  : caseDetails?.decision_date
                  ? caseDetails?.decision_date
                  : "N/A"}
              </p>
              <div className="flex gap-4 w-1/3 justify-between items-center px-5 py-3 border border-[#d9d9d9] dark:border-gray-600">
                <FaFilePdf className="text-2xl text-red-600 dark:text-red-400" />
                {/* <a
                  className="font-raleway px-4 text-sm bg-black rounded-md dark:text-black dark:bg-light-600 text-white py-1 font-medium"
                  href={timeline.pdf_path}
                  target="_blank"
                  rel="noreferrer"
                >
                  Read Now
                </a> */}
                <Sheet>
                  <SheetTrigger className="font-raleway px-2 border-b-2 dark:border-gray-600 font-bold">
                    Read Now
                  </SheetTrigger>
                  <SheetContent>
                    <object
                      data={timeline?.pdf_path ? timeline?.pdf_path : timeline}
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    >
                      <p>
                        <a
                          href={
                            timeline?.pdf_path ? timeline?.pdf_path : timeline
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <b>
                            <u>Click Here</u>
                          </b>
                        </a>{" "}
                        to view the PDF.
                      </p>
                    </object>
                  </SheetContent>
                </Sheet>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Timeline;
