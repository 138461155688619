// import axios from "axios";

// Utility function to check if the token is expired
// const isTokenExpired = () => {
//   const token = sessionStorage.getItem("access_token");
//   if (!token) return true;
//   try {
//     const payload = JSON.parse(atob(token.split(".")[1]));
//     const expirationTime = payload.exp;

//     const currentTime = Math.floor(Date.now() / 1000);

//     return expirationTime - currentTime <= 0; // 21599 <= 21600
//   } catch (error) {
//     console.error("Error decoding token:", error);
//     return true;
//   }
// };

// const axiosInstance = axios.create({
// baseURL: "https://api.caseminister.com/",
// });

// axiosInstance.interceptors.response.use(
// (response) => response,
// (error) => {
// Check specifically for a 401 Unauthorized response
// if (error.response && error.response.status === 401) {
// if (isTokenExpired()) {
// Perform the session cleanup
// sessionStorage.removeItem("access_token");
// sessionStorage.removeItem('user-info');
// sessionStorage.removeItem("isAuthenticated");

// Only navigate to login if the token has expired
// window.location.href = '/signup';
// }
// }
// For all other errors, just return the promise rejection
//     return Promise.reject(error);
//   }
// );

// export const axiosInterceptorInstance = () => {
//   const axiosInstance = axios.create({
//     baseURL: "https://api.caseminister.com/",
//   });

//   const refreshToken = sessionStorage.getItem("refresh_token");
//   console.log(refreshToken);

//   return axiosInstance.interceptors.response.use(
//     (response) => response,
//     async (error) => {
// Check specifically for a 401 Unauthorized response
//       const originalRequest = error.config;

//       if (error.response && error.response?.status === 401) {
// axios.defaults.withCredentials = true;
//         try {
//           const response = await axiosInstance.post("/api/signin/refresh/", {
//             refresh: refreshToken,
//           });

//           if (response["status"] === 200) {
//             return axiosInstance(originalRequest);
//           }
//         } catch (err) {
// window.location.href = "/login";
//           throw err;
//         }
//       }
//       throw error;
//     }
//   );
// };

// export default axiosInstance;

// if (isTokenExpired()) {
// Perform the session cleanup
// sessionStorage.removeItem("access_token");
// sessionStorage.removeItem('user-info');
// sessionStorage.removeItem('isAuthenticated');

// Only navigate to login if the token has expired
// window.location.href = '/signup';
// }

// For all other errors, just return the promise rejection
// return Promise.reject(error);

import axios from "axios";
import { toast } from "react-toastify";

// Utility function to check if the token is expired and schedule its removal
const scheduleTokenExpiration = () => {
  const token = sessionStorage.getItem("access_token");
  if (!token) return true;

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const expirationTime = payload.exp; // Expiration time in seconds
    const currentTime = Math.floor(Date.now() / 1000);

    if (expirationTime - currentTime <= 0) {
      // Token is already expired, remove it immediately
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("isAuthenticated");
      return true;
    }

    // Calculate time until expiration in milliseconds
    const timeUntilExpiration = (expirationTime - currentTime) * 1000;

    // Schedule token removal when it expires
    setTimeout(() => {
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("isAuthenticated");
      // console.log("Token has expired and was removed from session storage.");
      toast.error(
        "Your Session has Expired. Please login again to continue with your access."
      );
    }, timeUntilExpiration);

    return false;
  } catch (error) {
    console.error("Error decoding token:", error);
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("isAuthenticated");
    return true;
  }
};

// Check token expiration and schedule its cleanup
const isTokenExpired = () => {
  return scheduleTokenExpiration();
};

// Axios instance setup
const axiosInstance = axios.create({
  baseURL: "https://api.caseminister.com/",
});

// Axios response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Check specifically for a 401 Unauthorized response
    if (error.response && error.response.status === 401) {
      if (isTokenExpired()) {
        // Perform the session cleanup
        sessionStorage.removeItem("access_token");
        // sessionStorage.removeItem('user-info');
        sessionStorage.removeItem("isAuthenticated");

        // Only navigate to login if the token has expired
        // window.location.href = '/signup';
      }
    }
    // For all other errors, just return the promise rejection
    return Promise.reject(error);
  }
);

// Initial call to schedule the token expiration cleanup
isTokenExpired();

export default axiosInstance;

// For the waitlist page to appear Comment out ss.removeItem("isAuthenticated") from 155, 130 and 109
