// React Icons
import React, { useState } from "react";

// Data
import { statesAndCities } from "./data";

// Icons
import { TiTick } from "react-icons/ti";

// React Stepper
import { Stepper, Step } from "react-form-stepper";

// ShadCN Components
import { Input } from "../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { Textarea } from "../../components/ui/textarea";
import axiosInstance from "../../utils/axiosInstance";
import { toast } from "react-toastify";

const LawyerProfile = ({ userData, setUserData, id }) => {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <div className="flex flex-col">
      <div className="mb-6">
        <Stepper
          activeStep={activeStep}
          connectorStateColors={true}
          styleConfig={{
            activeBgColor: "gray",
            completedBgColor: "green",
            inactiveBgColor: "gray",
            size: "1.25em",
          }}
          connectorStyleConfig={{
            activeColor: "green",
            completedColor: "green",
          }}
        >
          <Step label="Personal Information">
            <TiTick />
          </Step>
          <Step label="Professional Information">
            <TiTick />
          </Step>
          <Step label="Online Presence">
            <TiTick />
          </Step>
          <Step label="Additional Information">
            <TiTick />
          </Step>
        </Stepper>
      </div>
      <FormInformation
        userData={userData}
        setUserData={setUserData}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        id={id}
      />
    </div>
  );
};

const FormInformation = ({
  userData,
  setUserData,
  activeStep,
  setActiveStep,
  id,
}) => {
  const states = Object.keys(statesAndCities);
  const cities = userData?.state ? statesAndCities[userData?.state] : [];
  const access_token = sessionStorage.getItem("access_token"); // Access Token

  const handleNextClick = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    let data = {};
    for (let keyValue of formData.entries()) {
      data[keyValue[0]] = keyValue[1];
    }

    delete data.first_name;
    delete data.last_name;

    data = {
      ...data,
      user: {
        first_name: userData.user.firstName,
        last_name: userData.user.lastName,
      },
    };

    console.log(data, "DATA");

    try {
      const response = await axiosInstance.put(`api/profile/${id}/`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });

      console.log(response.data);
      sessionStorage.setItem("user-info", JSON.stringify([response.data]));
      setUserData(response.data);
      if (activeStep !== 3) {
        setActiveStep((prev) => prev + 1);
      }
      if (activeStep === 3) {
        toast.success("Your details have been successfully saved.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (activeStep === 0) {
    return (
      <form onSubmit={handleNextClick} className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="w-1/2">
            <label>First Name *</label>
            <Input
              name="first_name"
              type="text"
              placeholder="Enter Your First Name"
              value={userData?.user?.firstName}
              onChange={(e) =>
                setUserData((prev) => ({
                  ...prev,
                  user: { ...prev.user, firstName: e.target.value },
                }))
              }
              required
            />
          </div>
          <div className="w-1/2">
            <label>Last Name *</label>
            <Input
              name="last_name"
              type="text"
              placeholder="Enter Your Last Name"
              value={userData?.user?.lastName}
              onChange={(e) =>
                setUserData((prev) => ({
                  ...prev,
                  user: { ...prev.user, lastName: e.target.value },
                }))
              }
              required
            />
          </div>
        </div>
        <div>
          <label>Email *</label>
          <Input
            name="email"
            type="email"
            placeholder="Enter Your Email"
            value={userData?.user?.email}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                user: { ...prev.user, email: e.target.value },
              }))
            }
            required
            disabled
          />
        </div>
        <div>
          <label>Date of Birth</label>
          <Input
            name="dob"
            type="date"
            placeholder="Enter Your DOB"
            value={userData?.dateOfBirth}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                dateOfBirth: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Mobile Number *</label>
          <Input
            name="mobile_number"
            type="text"
            placeholder="Enter Your Mobile Number"
            value={userData?.mobileNo}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                mobileNo: e.target.value,
              }))
            }
            required
          />
        </div>
        <div>
          <label>State *</label>
          <Select
            name="state"
            onValueChange={(value) => {
              setUserData((prev) => ({ ...prev, state: value, city: "" }));
            }}
            value={userData?.state}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select Your State" />
            </SelectTrigger>
            <SelectContent>
              {states.map((state) => (
                <SelectItem value={state} key={state}>
                  {state}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <label>City *</label>
          <Select
            name="city"
            onValueChange={(value) =>
              setUserData((prev) => ({ ...prev, city: value }))
            }
            disabled={!userData?.state}
            value={userData?.city}
          >
            <SelectTrigger className="w-full">
              <SelectValue
                placeholder={
                  !userData.state ? "Select State First" : "Select City"
                }
              />
            </SelectTrigger>
            <SelectContent>
              {cities?.map((city) => (
                <SelectItem key={city} value={city}>
                  {city}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="pincode">
          <label>Pin Code</label>
          <Input
            name="pincode"
            type="text"
            placeholder="Enter Your Pin Code"
            value={userData?.pinCode}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                pinCode: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Bio</label>
          <Textarea
            name="bio"
            placeholder="Enter Your Bio"
            value={userData?.bio}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                bio: e.target.value,
              }))
            }
          />
        </div>
        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => setActiveStep((prev) => prev - 1)}
            disabled={activeStep === 0}
            className="disabled:bg-gray-500 disabled:cursor-not-allowed dark:disabled:bg-gray-400 w-20 px-4 py-2 font-semibold bg-dark-600 text-gray-200 dark:bg-light-600 dark:text-gray-700"
          >
            Prev
          </button>
          <button
            type="submit"
            disabled={activeStep === 3}
            className="disabled:bg-gray-500 disabled:cursor-not-allowed dark:disabled:bg-gray-400 px-6 py-2 font-semibold bg-dark-600 text-gray-200 dark:bg-light-600 dark:text-gray-700"
          >
            Save & Next
          </button>
        </div>
      </form>
    );
  }

  if (activeStep === 1) {
    return (
      <form onSubmit={handleNextClick} className="flex flex-col gap-4">
        <div>
          <label>Practice Area</label>
          <Input
            name="practice_area"
            type="text"
            placeholder="Corporate Law, Intellectual Property Law"
            value={userData?.practice_area}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                practice_area: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Court of Practices</label>
          <Input
            name="court_of_practice"
            type="text"
            placeholder="District and High Courts"
            value={userData?.court_of_practice}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                court_of_practice: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Experience</label>
          <Input
            name="experience"
            type="number"
            placeholder="Total Experience"
            value={userData?.experience}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                experience: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Barcode Number</label>
          <Input
            name="barcode_number"
            type="text"
            placeholder="Enter Your Barcode Number"
            value={userData?.barcode_number}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                barcode_number: e.target.value,
              }))
            }
          />
        </div>

        <div>
          <label>Languages</label>
          <Input
            name="language"
            type="text"
            placeholder="Languages"
            value={userData?.language}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                language: e.target.value,
              }))
            }
          />
        </div>

        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => setActiveStep((prev) => prev - 1)}
            disabled={activeStep === 0}
            className="disabled:bg-gray-500 disabled:cursor-not-allowed dark:disabled:bg-gray-400 w-20 px-4 py-2 font-semibold bg-dark-600 text-gray-200 dark:bg-light-600 dark:text-gray-700"
          >
            Prev
          </button>
          <button
            type="submit"
            disabled={activeStep === 3}
            className="disabled:bg-gray-500 disabled:cursor-not-allowed dark:disabled:bg-gray-400 px-6 py-2 font-semibold bg-dark-600 text-gray-200 dark:bg-light-600 dark:text-gray-700"
          >
            Save & Next
          </button>
        </div>
      </form>
    );
  }

  if (activeStep === 2) {
    return (
      <form onSubmit={handleNextClick} className="flex flex-col gap-4">
        <div>
          <label>Facebook</label>
          <Input
            name="facebook"
            type="text"
            placeholder="Facebook Link"
            value={userData?.facebook}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                facebook: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>LinkedIn</label>
          <Input
            name="linkedin"
            type="text"
            placeholder="LinkedIn Link"
            value={userData?.linkedin}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                linkedin: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Twitter</label>
          <Input
            name="twitter"
            type="text"
            placeholder="Twitter Link"
            value={userData?.twitter}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                twitter: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Whatsapp Number</label>
          <Input
            name="whatsapp_number"
            type="text"
            placeholder="Enter Your Whatsapp Number"
            value={userData?.whatsapp_number}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                whatsapp_number: e.target.value,
              }))
            }
          />
        </div>

        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => setActiveStep((prev) => prev - 1)}
            disabled={activeStep === 0}
            className="disabled:bg-gray-500 disabled:cursor-not-allowed dark:disabled:bg-gray-400 w-20 px-4 py-2 font-semibold bg-dark-600 text-gray-200 dark:bg-light-600 dark:text-gray-700"
          >
            Prev
          </button>
          <button
            type="submit"
            disabled={activeStep === 3}
            className="disabled:bg-gray-500 disabled:cursor-not-allowed dark:disabled:bg-gray-400 px-6 py-2 font-semibold bg-dark-600 text-gray-200 dark:bg-light-600 dark:text-gray-700"
          >
            Save & Next
          </button>
        </div>
      </form>
    );
  }

  if (activeStep === 3) {
    return (
      <form onSubmit={handleNextClick} className="flex flex-col gap-4">
        <div>
          <label>Published Works</label>
          <Input
            name="published_works"
            type="text"
            placeholder="Published Works"
            value={userData?.published_works}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                published_works: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Honour and Rewards</label>
          <Input
            name="honors_and_awards"
            type="text"
            placeholder="Honour and Rewards"
            value={userData?.honors_and_awards}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                honors_and_awards: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <label>Consultation Fees</label>
          <Input
            name="consultation_fees"
            type="text"
            placeholder="Consultation Fees"
            value={userData?.consultation_fees}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                consultation_fees: e.target.value,
              }))
            }
          />
        </div>

        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => setActiveStep((prev) => prev - 1)}
            disabled={activeStep === 0}
            className="disabled:bg-gray-500 disabled:cursor-not-allowed dark:disabled:bg-gray-400 w-20 px-4 py-2 font-semibold bg-dark-600 text-gray-200 dark:bg-light-600 dark:text-gray-700"
          >
            Prev
          </button>
          <button
            type="submit"
            className=" dark:disabled:bg-gray-400 px-6 py-2 font-semibold bg-dark-600 text-gray-200 dark:bg-light-600 dark:text-gray-700"
          >
            {activeStep === 3 ? "Save Details" : "Save & Next"}
          </button>
        </div>
      </form>
    );
  }
};

export default LawyerProfile;
