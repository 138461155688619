// React Utils
import React from "react";

// Outlext Context
import { useOutletContext } from "react-router-dom";

// Recharts PieChart
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";

// Recharts Table
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";

const OpposingCounselAnalytics = () => {
  // Data from HighCourtDelhiDetails Component Outlet
  const { caseDetails } = useOutletContext();

  const COLORS = ["#16A34A", "#DC2626"];

  // Formatting Text According To Markdown.
  const formatText = (text) => {
    if (!text) return null;

    const lines = text.split("\n");

    return lines
      .map((line, index) => {
        if (!line.trim()) return null;

        // Handle headings
        const headingMatch = line.match(/^(#+)\s+(.*)/);
        if (headingMatch) {
          //   const level = headingMatch[1].length;
          const content = headingMatch[2].replace(/\*\*(.*?)\*\*/g, "$1");
          return (
            <div className="mb-4">
              <strong className="text-2xl" key={index}>
                {content}
              </strong>
            </div>
          );
        }

        const numberedMatch = line.match(/^(\d+\.\s+)\*\*(.*?)\*\*$/);
        if (numberedMatch) {
          const numberPart = numberedMatch[1]; // e.g., "1. "
          const content = numberedMatch[2].trim(); // Get the text after ** and remove it
          return (
            <div key={index}>
              {numberPart}
              <strong>{content}</strong>
            </div>
          );
        }

        // Handle list items with bold text
        if (line.startsWith("-**")) {
          const content = line.substring(3, line.length - 2).trim();
          return (
            <ul key={index}>
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }
        if (line.startsWith("-**")) {
          const content = line.substring(8).trim();
          return (
            <ul key={index}>
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }
        if (line.startsWith("   - **")) {
          const content = line.substring(7).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul key={index}>
              <li
                className="list-disc mb-2 tracking-wide leading-[1.6]"
                style={{ marginLeft: "10px" }}
              >
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("  - ")) {
          const content = line.substring(4).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul key={index} style={{ marginLeft: "10px" }}>
              <li
                className="list-disc mb-2 tracking-wide leading-[1.6]"
                style={{ marginLeft: "10px" }}
              >
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("   - ")) {
          const content = line.substring(4).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul key={index} style={{ marginLeft: "10px" }}>
              <li
                className="list-disc mb-2 tracking-wide leading-[1.6]"
                style={{ marginLeft: "10px" }}
              >
                {content}
              </li>
            </ul>
          );
        }

        if (line.startsWith("     - ")) {
          const content = line.substring(6).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul key={index} style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }

        // Handle regular list items
        if (line.startsWith("-")) {
          const cleanLine = line.substring(1).trim();
          const contentWithoutBold = cleanLine.replace(/\*\*(.*?)\*\*/g, "$1");
          return (
            <p key={index} className="mb-3 tracking-wide leading-[1.6] px-6">
              <li>{contentWithoutBold}</li>
            </p>
          );
        }

        if (line.startsWith("**") && line.endsWith("**")) {
          const cleanLine = line.substring(2, line.length - 2).trim(); // Remove the first and last two asterisks
          return (
            <strong key={index}>
              <p>{cleanLine}</p>
            </strong>
          );
        }

        // Handle bold text within a paragraph
        const parts = line
          .replace(/\*\*$/, "")
          .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
          .split(/(<strong>.*?<\/strong>)/)
          .map((part, idx) => {
            if (part.startsWith("<strong>") && part.endsWith("</strong>")) {
              const cleanText = part.substring(8, part.length - 9);
              return (
                <strong className="mb-10" key={idx}>
                  {cleanText}
                </strong>
              );
            } else {
              return <span key={idx}>{part}</span>;
            }
          });

        return (
          <p
            className="tracking-wide leading-[1.6] mb-3"
            key={index}
            // style={{ textAlign: "justify" }}
          >
            {parts}
          </p>
        );
      })
      .filter((component) => component !== null);
  };

  return (
    <div className="flex flex-col gap-10">
      {/* Heading Section */}
      <div>
        <p className="font-raleway font-semibold text-xl border-b-2 w-fit">
          Opposing Counsel Analytics
        </p>
      </div>
      {caseDetails?.analytics?.["Respondent Advocates"].length > 0
        ? caseDetails?.analytics?.["Respondent Advocates"].map(
            (advocate, index) => (
              <React.Fragment key={index}>
                {advocate?.["Total Cases"] >= 5 ? (
                  <div className="flex flex-col gap-16">
                    <div className="flex flex-col gap-10">
                      <div>
                        <p className="font-raleway font-semibold text-2xl">
                          {advocate?.["Advocate Name"]}
                        </p>
                      </div>
                      {/* Total Cases Section */}
                      <div className="flex px-10 justify-between">
                        {/* Left Section */}
                        <div className="flex flex-col gap-4 w-[500px]">
                          <div className="border px-3 pt-3 pb-8 rounded-md border-black/10 dark:border-gray-600">
                            <div className="flex flex-col gap-1">
                              <p className="font-raleway font-semibold text-lg opacity-50 dark:opacity-100">
                                Total Cases
                              </p>
                              <p className="font-roboto font-medium text-5xl">
                                {advocate?.["Total Cases"]}
                              </p>
                            </div>
                          </div>
                          <div className="flex gap-4">
                            <div className="border px-3 pt-3 pb-8 w-1/2 rounded-md border-black/10 dark:border-gray-600">
                              <div className="flex flex-col gap-1">
                                <p className="font-raleway font-semibold text-lg opacity-50 dark:opacity-100">
                                  Bail Granted
                                </p>
                                <p className="font-roboto font-medium text-5xl text-green-600 dark:text-green-500">
                                  {advocate?.["Successful Cases"]}
                                </p>
                              </div>
                            </div>
                            <div className="border px-3 pt-3 pb-8 w-1/2 rounded-md border-black/10 dark:border-gray-600">
                              <div className="flex flex-col gap-1">
                                <p className="font-raleway font-semibold text-lg opacity-50 dark:opacity-100">
                                  Bail Denied
                                </p>
                                <p className="font-roboto font-medium text-5xl text-red-600 dark:text-red-500">
                                  {advocate?.["Denied Cases"]}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Right Section */}
                        <div className="select-none relative">
                          <DrawPieChart data={advocate} COLORS={COLORS} />
                          <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-30%] text-center">
                            <p className="font-roboto font-semibold text-xl">
                              Total Cases
                            </p>
                            <p className="font-roboto font-semibold text-xl">
                              ({advocate?.["Total Cases"]})
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <hr /> */}
                      {advocate?.insight ? (
                        <div className="flex flex-col gap-6 mb-10">
                          <div>
                            <p className="font-raleway font-semibold text-xl border-b-2 w-fit">
                              Argument Insights
                            </p>
                          </div>
                          <div>{formatText(advocate.insight)}</div>
                        </div>
                      ) : (
                        "No Insights Available"
                      )}
                      {/* <hr /> */}
                      {advocate?.judge ? (
                        <div className="flex flex-col gap-6">
                          <div>
                            <p className="font-raleway font-semibold text-xl border-b-2 w-fit">
                              Advocate's Performance Under Judges
                            </p>
                          </div>
                          <div>
                            <Table className="font-bold">
                              <TableHeader className="bg-gray-100 dark:bg-dark-450">
                                <TableRow>
                                  <TableHead className="text-left border-r">
                                    Judge Name
                                  </TableHead>
                                  <TableHead className="text-center border-r">
                                    Total Cases
                                  </TableHead>
                                  <TableHead className="text-center border-r">
                                    Granted Cases
                                  </TableHead>
                                  <TableHead className="text-center">
                                    Denied Cases
                                  </TableHead>
                                </TableRow>
                              </TableHeader>
                              <TableBody>
                                {advocate?.judge?.map((section, index) => (
                                  <TableRow key={index}>
                                    <TableCell className="text-left border-r">
                                      {section?.["Judge Name"]}
                                    </TableCell>
                                    <TableCell className="text-center border-r">
                                      {section?.["Total Cases"]}
                                    </TableCell>
                                    <TableCell className="text-center border-r">
                                      {section?.["Granted Cases"]}
                                      <span className="text-green-500 font-medium ml-2">
                                        (
                                        {(
                                          (section?.["Granted Cases"] /
                                            section?.["Total Cases"]) *
                                          100
                                        ).toFixed(2)}
                                        %)
                                      </span>
                                    </TableCell>
                                    <TableCell className="text-center">
                                      {section?.["Denied Cases"]}
                                      <span className="text-red-500 font-medium ml-2">
                                        (
                                        {(
                                          (section?.["Denied Cases"] /
                                            section?.["Total Cases"]) *
                                          100
                                        ).toFixed(2)}
                                        %)
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </div>
                          {/* <hr /> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <hr className="mb-10" />
                  </div>
                ) : (
                  ""
                )}
              </React.Fragment>
            )
          )
        : "No Opposing Counsel data found."}
    </div>
  );
};

const DrawPieChart = ({ data, COLORS }) => {
  const dataRequiredForm = [
    {
      name: "Bail Granted",
      value: data?.["Successful Cases"],
    },
    {
      name: "Bail Denied",
      value: data?.["Denied Cases"],
    },
  ];

  const CustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.8;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fontSize="14px" // Font size
        fontFamily="Arial, sans-serif" // Font family
        fontWeight="bold" // Font weight
        textAnchor={x > cx ? "start" : "end"} // Horizontal alignment
        dominantBaseline="central" // Vertical alignment
        className="fill-gray-700 dark:fill-gray-200"
      >
        {`${dataRequiredForm[index].name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };
  return (
    <>
      <PieChart width={600} height={350}>
        <Legend verticalAlign="top" height={20} />
        <Tooltip />
        <Pie
          // isAnimationActive={false}
          data={dataRequiredForm}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={120}
          innerRadius={80}
          fill="#82ca9d"
          paddingAngle={4}
          startAngle={135}
          endAngle={495}
          label={CustomLabel}
        >
          {dataRequiredForm.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </>
  );
};

export default OpposingCounselAnalytics;
